const Menu = {

	activeSection: {

		id: '',

		setActiveMenuItem: () => {

			let allMenuItems = document.querySelectorAll('a[data-menu-item]')
			if (allMenuItems.length > 0) {
				allMenuItems.forEach(menuItem => {
					menuItem.classList.remove('active')
				});
			}

			if (Menu.activeSection.id !== '') {
				let menuItems = document.querySelectorAll('a[data-menu-item="' + Menu.activeSection.id + '"]')
				if (menuItems.length > 0) {
					menuItems.forEach(menuItem => {
						menuItem.classList.add('active')
					});
				}
			}
		},

		trace: () => {

			document.querySelectorAll('section').forEach((section) => {
				let sectionDimensions = section.getBoundingClientRect()
				let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

				if (sectionDimensions.top <= viewHeight * 0.5) {
					Menu.activeSection.id = section.id
				}
			})

			Menu.activeSection.setActiveMenuItem()

		},

		init: () => {

			Menu.activeSection.trace()


			window.onscroll = function (e) {
				Menu.activeSection.trace()

			}
		},
	},



	scrollTo: (event, elementId) => {

		if (window.location.href !== event.target.href.split('#')[0]) {
			return false;
		}

		event.preventDefault()

		const offset = 0;
		const y = document.querySelector('#' + elementId).getBoundingClientRect().top + window.pageYOffset + offset;
		window.scrollTo({ top: y, behavior: 'smooth' });

		Menu.mobile.hide()
	},


	scrolledState: {
		toggle: () => {
			if (window.scrollY > 10) {
				document.body.classList.add('scrolled')
			} else {
				document.body.classList.remove('scrolled')
			}
		},

		init: () => {
			Menu.scrolledState.toggle()
			window.addEventListener('scroll', () => {
				Menu.scrolledState.toggle()
			})
		},
	},



	mobile: {
		show: () => {
			document.querySelector('#menu-button').classList.add('active')
			document.querySelector('#menu').classList.add('active')
		},
		hide: () => {
			document.querySelector('#menu-button').classList.remove('active')
			document.querySelector('#menu').classList.remove('active')
		},
		initClickOutside: () => {
			let ignoredElement = document.querySelector('#menu .content')
			let ignoredElement_2 = document.querySelector('.menu-links')
			window.addEventListener('mouseup', function (event) {
				if (
					event.target != ignoredElement && event.target.parentNode != ignoredElement
					&& event.target != ignoredElement_2 && event.target.parentNode != ignoredElement_2
				) {
					Menu.mobile.hide()
				}
			});
		},
	},



	init: () => {

		Menu.scrolledState.init()

		Menu.mobile.initClickOutside()

		Menu.activeSection.init()

	}
}


window.addEventListener('load', () => {

	Menu.init()

}, false);