window.addEventListener('load', () => {

	gsap.registerPlugin(ScrollTrigger)

	const globalAnimationOptions = {
		markers: false,
		start: '-50px center',
		end: '+=40%',
		scrub: false,
	}


	gsap.to('#home .title', {
		y: 0,
		opacity: 1,
		delay: 0.2,
	});




	gsap.to('#explore .image', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#explore',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});

	gsap.to('#explore .text-section', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#explore',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});





	gsap.to('#about-us .text-section', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#about-us',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});





	gsap.to('#why .image', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#why',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});

	gsap.to('#why .first', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#why .first',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});

	gsap.to('#why .second', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#why .second',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});

	gsap.to('#why .third', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#why .third',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});

	gsap.to('#why .last', {
		x: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#why .last',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});




	gsap.to('#contact-us .text-container', {
		y: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#contact-us',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});

	gsap.to('#contact-us form', {
		y: 0,
		opacity: 1,
		scrollTrigger: {
			trigger: '#contact-us',
			start: globalAnimationOptions.start,
			end: globalAnimationOptions.end,
			scrub: globalAnimationOptions.scrub,
			markers: globalAnimationOptions.markers,
		}
	});


}, false);