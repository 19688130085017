window.addEventListener('load', () => {

	new Swiper('.featured-properties-slider', {
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: {
			nextEl: '.button-next',
			prevEl: '.button-prev',
		},
		pagination: {
			el: '.slider-pagination',
		},

		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 30
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 30
			},
		},
	});
}, false);