const Form = {


	form: null,

	validator: {
		stringEmpty: (string) => {
			return (!string || string.length === 0);
		},
		invalidEmail: (email) => {
			return !String(email)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
		},
	},


	makeInputsValid: () => {
		Form.form.querySelectorAll('[data-required="true"]').forEach((requiredInputField) => {
			requiredInputField.classList.remove('invalid')
		})
	},

	validateFormAndGetData: () => {
		let valid = true

		let first_name = Form.form.querySelector('[name="first_name"]')
		let last_name = Form.form.querySelector('[name="last_name"]')
		let email = Form.form.querySelector('[name="email"]')
		let message = Form.form.querySelector('[name="message"]')

		if (Form.validator.stringEmpty(first_name.value)) {
			first_name.classList.add('invalid')
			valid = false
		}

		if (Form.validator.stringEmpty(last_name.value)) {
			last_name.classList.add('invalid')
			valid = false
		}
		if (Form.validator.invalidEmail(email.value)) {
			email.classList.add('invalid')
			valid = false
		}
		if (Form.validator.stringEmpty(message.value)) {
			message.classList.add('invalid')
			valid = false
		}

		if (valid === false) {
			return false
		}

		return {
			first_name: first_name.value,
			last_name: last_name.value,
			email: email.value,
			message: message.value,
			token: Form.form.querySelector('[name="token"]').value,
		}
	},


	send: async (data) => {

		Form.form.classList.add('loading')

		const response = await fetch('/contact.php', {
			method: 'POST',
			body: JSON.stringify(data),
		})
			.then((response) => {
				return response.json()
			})
			.then((response) => {
				// console.log(response)
			})
			.catch((error) => {
				console.error(error)
			})
			.finally(() => {
				Form.form.reset()
				Form.form.classList.remove('loading')
			})

	},


	submit: (event) => {

		event.preventDefault()

		Form.form = document.querySelector('#contact-us-form')

		Form.makeInputsValid()



		let data = Form.validateFormAndGetData()

		if (data === false) {
			console.warn('Form data invalid');
			return false;
		}

		console.log('data', data);

		Form.send(data)

	}


}
