
const VideoObject = {

	init: () => {
		let playButton = document.querySelector('.video-container')

		VideoObject.playButtonClicked(playButton)
	},

	play: (video) => {
		video.play();
		VideoObject.setPlayButtonStatus(video, 'play')
	},


	pause: (video) => {
		video.pause();
		VideoObject.setPlayButtonStatus(video, 'pause')
	},

	setPlayButtonStatus: (video, status) => {
		video.closest('.video-container').setAttribute('data-play-status', status)
	},

	toggleVideo: (_video) => {
		document.querySelectorAll('video').forEach(function (video) {
			if (video !== _video) {
				VideoObject.pause(video)
			}
		});

		if (!_video.paused) {
			VideoObject.pause(_video)
		} else {
			VideoObject.play(_video)
		}
	},

	playButtonClicked: (playButton) => {
		playButton.addEventListener('click', (event) => {
			event.preventDefault();

			VideoObject.toggleVideo(event.currentTarget.querySelector('video'))
		});
	},
}


window.addEventListener('load', () => {

	VideoObject.init()

}, false);